.skills__container {
  grid-template-columns: repeat(2, 350px);
  column-gap: 3rem;
  justify-content: center;
}

.skills__content {
  background-color: var(--container-color);
  border-radius: 1rem;
  padding: 2rem 4rem;
}

.skills__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  text-align: center;
  margin-bottom: var(--mb-1-5);
}

.skills__box {
  display: flex;
  justify-content: center;
  column-gap: 2.5rem;
}

.skills__group {
  display: grid;
  align-items: flex-start;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1.5rem;
  column-gap: 3rem;
}

.skills__data {
  display: flex;
  column-gap: 0.5rem;
}

.skills .badge-icon {
  height: 1rem;
  width: 1rem;
  font-size: 1rem;
  color: var(--title-color);
}

.skills__name {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  line-height: 18px;
}

.skills__level {
  font-size: var(--tiny-font-size);
}

/* ============= BREAKPOINTS ================ */
/* Large devices */
@media screen and (max-width: 992px) {
  .container {
    margin-left: var(--mb-1-5);
    margin-right: var(--mb-1-5);
  }

  .button {
    padding: 1rem 1.75rem;
  }

  .button__icon {
    width: 22px;
    height: 22px;
  }
}

/* Medium devices */
@media screen and (max-width: 768px) {
  .skills__container {
    grid-template-columns: max-content;
    row-gap: 2rem;
  }
}

@media screen and (max-width: 576px) {
  .skills__container {
    grid-template-columns: 1fr;
  }

  .skills__content {
    padding: 1.5rem;
  }
}

/* Small devices */
@media screen and (max-width: 350px) {
  .skills__box {
    column-gap: 1.25rem;
  }

  .skills__name {
    font-size: var(--smaller-font-size);
  }
}
