.home__container {
  row-gap: 7rem;
}

.home__content {
  grid-template-columns: 116px repeat(2, 1fr);
  padding-top: 5.5rem;
  column-gap: 2rem;
  align-items: center;
}

.home__social {
  display: grid;
  grid-template-columns: max-content;
  row-gap: 1rem;
}

.home__social-icon {
  font-size: 1.25rem;
  color: var(--title-color);
}

.home__social-icon:hover {
  color: var(--text-color);
  transition: 1s;
}

.home__title {
  font-size: var(--big-font-size);
  margin-bottom: var(--mb-0-25);
}

.home__subtitle {
  position: relative;
  font-size: var(--h3-font-size);
  /* padding-left: 5.4rem; */
  font-weight: var(--light-font-weight);
  margin-bottom: var(--mb-1);
}

/* .home__subtitle::before {
  content: '';
  position: absolute;
  left: 0;
  top: 1rem;
  width: 70px;
  height: 1px;
  background-color: var(--text-color);
} */

.home__description {
  max-width: 400px;
  margin-bottom: var(--mb-3);
}

.home__img {
  background: url(../../assets/profile-pic.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* box-shadow: inset 0 0 0 9px rgb(255 255 255 / 30%); */
  order: 1;
  justify-content: center;
  height: 300px;
  width: 300px;
  animation: profile__animation 10s ease-in-out infinite 1s;
}

@keyframes profile__animation {
  0% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  }
  50% {
    border-radius: 60% 20% 30% 70% / 70% 70% 30% 30%;
  }
  100% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  }
}

.home__scroll {
  margin-left: 9.25rem;
}

.wheel {
  animation: scroll 2s ease infinite;
  color: var(--title-color);
}

.wheel:hover {
  color: var(--text-color);
  transition: 1s;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

/* ============= BREAKPOINTS ================ */
/* Large devices */
@media screen and (max-width: 992px) {
  .home__content {
    grid-template-columns: 100px repeat(2, 1fr);
  }

  .home__subtitle {
    padding-left: 3.75rem;
    margin-bottom: var(--mb-1);
  }

  .home__subtitle::before {
    width: 42px;
    top: 0.8rem;
  }

  .home__description {
    max-width: initial;
    margin-bottom: var(--mb-2-5);
  }

  .home__img {
    height: 250px;
    width: 250px;
  }
}

/* Medium devices */
@media screen and (max-width: 768px) {
  .home__content {
    grid-template-columns: 0.5fr 3fr;
    padding-top: 3rem;
  }

  .home__img {
    order: initial;
    justify-self: initial;
    height: 200px;
    width: 200px;
  }

  .home__scroll {
    display: none;
  }

  .home__data {
    grid-column: 1/3;
  }
}

/* Small devices */
@media screen and (max-width: 350px) {
  .home__img {
    height: 180;
    width: 180;
  }
}
